import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import skill1 from '../assets/skill1.webp'
import analysis from '../assets/qualitysocre.webp'
import edulevel from '../assets/edulevel.webp'
import expertise from '../assets/expertise.webp'
import communicate from '../assets/communicate.webp'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


const Specials = () => {
    const sliderParams = {
        arrows: true,
        autoPlaySpeed: 10000,
        centerMode: false,
        slidesToSlide: 1,
        containerClass: 'carousel-container',
        responsive: responsive
    };
    return (
        <section className='py-10 bg-grayMain px-3'>
            <div className='max-w-[85rem] m-auto flex justify-evenly flex-col gap-10 md:flex-row'>
                <div className='flex flex-col gap-4 items-center'>
                    <p className='text-3xl md:text-4xl md:leading-[3rem] font-bold md:w-3/4 w-4/5 text-center'>What Makes Assignment Help From Our Experts So Special?</p>
                    <p className='md:text-lg font-medium md:w-3/4 w-4/5 text-base'>
                        GetaWriter gives you a chance to cooperate with top experts within different fields of study.
                        Work on your projects being guided by a professional and be sure everything will be done by the deadline
                    </p>

                </div>
                <div className='md:w-4/6 w-full'>
                    <Carousel {...sliderParams}>
                        <div className='sm:mr-10 mr-4'>
                            <img src={skill1} alt="" className='rounded-t-xl' />
                            <div className='flex flex-col gap-4 px-10 py-5 w-full bg-white rounded-b-xl'>
                                <p className='font-bold text-lg'>Skill Test</p>
                                <p>
                                    We test each candidate by examining their skills and knowledge with various examinations in different academic fields.
                                    We make sure the education level and work experience that the expert describes on their profile
                                    match their real expertise and that they really are capable of providing you with high-quality online assignment help.
                                </p>

                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4'>
                            <img src={analysis} alt="" className='rounded-t-xl' />
                            <div className='flex flex-col gap-4 px-10 py-5 w-full bg-white rounded-b-xl'>
                                <p className='font-bold text-lg'>Quality Analysis</p>
                                <p>
                                    We developed an AI-based system that analyses the quality of each expert’s performance.
                                    We form a rating based on the data we collect and reviews that experts get from their clients,
                                    so you can be sure the assignment helper you find on GetaWriter is skilled and reliable. We also check each review for reliability.
                                </p>

                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4'>
                            <img src={edulevel} alt="" className='rounded-t-xl' />
                            <div className='flex flex-col gap-4 px-10 py-5 w-full bg-white rounded-b-xl'>
                                <p className='font-bold text-lg'>Education Level</p>
                                <p>
                                    Our experts are graduates of the world’s leading universities.
                                    We carefully check diplomas and credentials to make sure each document is legit and matches the education level that is claimed.
                                </p>

                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4'>
                            <img src={expertise} alt="" className='rounded-t-xl' />
                            <div className='flex flex-col gap-4 px-10 py-5 w-full bg-white rounded-b-xl'>
                                <p className='font-bold text-lg'>Broad Expertise</p>
                                <p>
                                    It doesn't matter how complicated your assignment is.
                                    We can find a specialist that is competent enough to provide you with a clear and effective solution to any academic problem.
                                    Can't find reliable sources with strong arguments and need assignment writing help?
                                    Or can't wrap your mind over a polynomial equation and want assistance with algebra? GetaWriter experts can nail it.
                                </p>

                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4'>
                            <img src={communicate} alt="" className='rounded-t-xl' />
                            <div className='flex flex-col gap-4 px-10 py-5 w-full bg-white rounded-b-xl'>
                                <p className='font-bold text-lg'>Communication Skills</p>
                                <p>
                                    You can chat with all the experts who offer you their assignment help, even before you hire them.
                                    Make your decision based not only on reviews and ratings but also on your own impression of the direct interaction with the person.
                                    After you've chosen the right professional with whom to cooperate,
                                    you can still communicate with them via chat to discuss your project and track the progress.
                                </p>

                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
    );
}

export default Specials