import React from 'react'
import {MdArrowUpward } from 'react-icons/md';
import Carousel from 'react-multi-carousel'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const AssignmentTypes = () => {
    const sliderParams = {
        arrows: true,
        centerMode: false,
        slidesToSlide: 1,
        responsive: responsive
    };

    return (
        <section className='py-10 bg-grayMain px-3'>
            <div className='max-w-[85rem] m-auto flex justify-evenly flex-col gap-4 items-center'>
                <div className='flex flex-col gap-6 pb-4 items-center text-center'>
                    <p className='text-3xl md:text-5xl md:leading-[3rem] font-bold md:w-full w-4/5 text-center'>Most Popular Assignment Types</p>
                    <p>Our experts can cover all kinds of assignments — pick the one you need help with, and we’ll find the right experts to handle it.</p>
                </div>
                <div className='flex flex-col md:flex-row w-full gap-4 items-center flex-[6]'>
                    <div className='md:w-2/3 w-full rounded-lg'>
                        <Carousel {...sliderParams} autoPlay={false} className='rounded-xl' >
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Essay</p>
                                    <p>
                                        Whether you struggle with making an outline or finding convincing argumentation,
                                        our experts can provide you with assignment writing help and
                                        cooperate with you on your argumentative, narrative, or any other kind of essay.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Research paper</p>
                                    <p>
                                        Get our professional assignment help while working on your research.
                                        Make sure your academic paper is perfect from the title page and abstract to the references section.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Coursework</p>
                                    <p>
                                        Don't worry about your overall course scores. Hire an expert to cooperate with you on your assignment,
                                        no matter how complicated and time-consuming it is.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Personal statement</p>
                                    <p>
                                        Make the admissions committee notice you among all the other potential students.
                                        Request help with your application essay and enroll at your dream college or university.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Speech</p>
                                    <p>
                                        Want to write a clear, convincing, and engaging speech?
                                        Get advice from professional writers, journalists, and professors among GetaWriter experts.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Case studies</p>
                                    <p>
                                        Consult with people with real practical experience within an academic
                                        field to successfully perform a detailed study of a subject, place, event, person, group, organization, or phenomenon.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Term paper</p>
                                    <p>
                                        Make sure your academic term ends up successfully. Let an expert calm your worries down with their professional assistance.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Thesis or dissertation</p>
                                    <p>
                                        Even if all you need is just proofreading,
                                        feel free to entrust the double-check of your fundamental academic article to an expert who has already earned a degree.
                                    </p>
                                </div>
                            </div>
                            <div className='sm:mr-10 mr-4 h-full bg-white rounded-xl'>
                                <div className='flex flex-col gap-4 px-10 py-5 w-full'>
                                    <p className='font-bold text-2xl'>Annotated bibliography</p>
                                    <p>
                                        Get assistance not only finding great sources to back up your research but also listing them properly.
                                    </p>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                    <div className='flex flex-col gap-4 text-white bg-black p-8 rounded-xl md:w-2/3 h-full'>
                        <h1 className='font-bold text-2xl w-4/5'>Didn't find what you were looking for?</h1>
                        <p className='font-semibold text-lg'>Order a custom job from an expert 👍</p>
                        <button className='bg-green-500 items-center py-4 rounded-[5px] font-bold hover:bg-green-400'>
                            <a href='#reachout'
                                className='flex justify-center gap-4 items-center'>
                             <MdArrowUpward className='animate-bounce'/> Find an Expert 
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AssignmentTypes