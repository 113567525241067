import React from 'react'
import { MdAccountBalance, MdOutlineManageAccounts } from 'react-icons/md'
import { SiMarketo } from 'react-icons/si'
import { BsCurrencyDollar, BsFillAwardFill } from 'react-icons/bs'
import { FcStatistics } from 'react-icons/fc'
import { FaUserNurse } from 'react-icons/fa'
import { RiEnglishInput } from 'react-icons/ri'

const Popular = () => {
      return (
            <section className='py-10 px-3' id='subjects'>
                  <div className='max-w-[85rem] m-auto flex justify-between flex-col gap-4 items-center'>
                        <div className='flex flex-col gap-6 pb-4 text-center items-center'>
                              <p className='text-3xl md:text-5xl md:leading-[3rem] font-bold md:w-full w-4/5 text-center'>Most Popular Assignment Subjects</p>
                              <p>No topic is out of reach here on GetaWriter. Find an expert within any area and get their professional assistance.</p>
                        </div>
                        <div className='md:grid md:gap-x-8 md:gap-y-12 md:grid-cols-3 flex flex-col gap-5'>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <BsCurrencyDollar className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Economics</h1>
                                    </div>
                                    <p>
                                          From macroeconomics to microeconomics, international economics to personal finance economics,
                                          we have experts who can cover any type of request regarding assignment help online.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <MdAccountBalance className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Accounting</h1>
                                    </div>
                                    <p>
                                          Navigate a variety of financial terms and figure out how to operate them properly while cooperating with top experts within the field.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <SiMarketo className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Marketing</h1>
                                    </div>
                                    <p>
                                          Find an experienced professional to let you figure out what are SWOT analysis, pricing strategies, promotional tools, etc.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <FcStatistics className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Statistics</h1>
                                    </div>
                                    <p>
                                          Learn about the best ways of collection, organization, analysis, interpretation,
                                          and presentation of data first hand — find an expert in statistics to help with your assignments.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <MdOutlineManageAccounts className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Management</h1>
                                    </div>
                                    <p>
                                          Whether it's a business, a non-profit organization, or a government institution you are going to work with in the future,
                                          start your path in management by requesting homework assignment help from a professional.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <BsFillAwardFill className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Law</h1>
                                    </div>
                                    <p>
                                          We will match you with an assignment helper specializing in
                                          legal studies so that you can ask any questions related to the discipline and get clear and accurate answers.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <FaUserNurse className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Health Care</h1>
                                    </div>
                                    <p>
                                          We will match you with an assignment helper specializing in
                                          Health Care studies so that you can ask any questions related to the discipline and get clear and accurate answers.
                                    </p>
                              </div>
                              <div className='p-10 bg-grayMain flex flex-col gap-4 rounded-xl'>
                                    <div className='flex gap-2 items-center p-2'>
                                          <RiEnglishInput className='w-10 h-10 text-white bg-green-500 rounded-full p-1' />
                                          <h1 className='font-bold text-black text-xl'>Literature</h1>
                                    </div>
                                    <p>
                                          We will match you with an assignment helper specializing in
                                          literature studies so that you can ask any questions related to the discipline and get clear and accurate answers.
                                    </p>
                              </div>
                        </div>
                  </div>
            </section>
      )
}

export default Popular