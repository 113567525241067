import React, { useState } from 'react'
import { FaMoneyCheckAlt } from 'react-icons/fa'
import { MdSupportAgent } from 'react-icons/md'
import { GiPayMoney } from 'react-icons/gi'

import { PersonalInfo } from '../utls/Data'

const ReachOut = () => {
    const [plan, setPlan] = useState('')
    const [timeline, setTimeline] = useState(new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toDateString())

    return (
        <section className='text-white py-16 bg-primaryColor px-3' id='reachout'>
            <div className='max-w-[85rem] m-auto flex justify-evenly flex-col gap-4 items-center'>
                <div className='flex flex-col gap-6 pb-4 justify-center items-center'>
                    <p className='text-3xl md:text-5xl md:leading-[3rem] font-bold text-center w-2/3'>Reach Out to the Expert Now</p>
                    <p className='text-center font-bold text-base md:w-2/3 w-[90%]'>Running out of time to get it done all by yourself? No problem. Just give us a brief project description.
                        Within three minutes, we can find an expert to provide you with professional assignment help.
                    </p>
                </div>
                <div className='flex md:flex-row flex-col gap-2 w-2/3 flex-[5]'>
                    <input type="text" name="contact" id="" placeholder='Personal self-care plan'
                        value={plan} onChange={(e) => setPlan(e.target.value)}
                        className='p-3 w-full flex-[3] outline-black border-[2px] border-black text-black rounded-md' />
                    <select name="" id=""
                        onChange={e => setTimeline(e.target.value)}
                        value={timeline}
                        className='p-3 w-full flex-[1] outline-black border-[2px] border-black text-black rounded-md'
                        placeholder='Personal self-care plan' >
                        <option value={`${new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toDateString()}`} >Tomorrow</option>
                        <option value={`${new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toDateString()}`}>In 3 days</option>
                        <option value={`${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toDateString()}`}>In a Week</option>
                        <option value={`${new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toDateString()}`}>In a Month</option>
                    </select>
                    <button className='w-full bg-yellow-400 py-2 flex-[1] text-black rounded-sm'>
                        <a href={`mailto:${PersonalInfo.email}?subject=ALERT!!! NEW PROJECT DUE BY ${timeline.toUpperCase()}&body=${plan}`}
                            className='font-medium text-base' rel="noopener noreferrer">Find an Expert</a>
                    </button>
                </div>
                <div className='flex gap-4 md:w-1/2 px-4 justify-evenly font-bold text-lg'>
                    <p className='flex gap-2 items-center text-sm'> <GiPayMoney className='w-6 h-6' /> Money-Back Guarantee</p>
                    <p className='flex gap-2 items-center text-sm'><MdSupportAgent className='w-8 h-8' /> Support 24/7</p>
                    <p className='flex gap-2 items-center text-sm'> <FaMoneyCheckAlt className='w-6 h-6' /> No Hidden Charges</p>
                </div>
            </div>

        </section>

    )
}

export default ReachOut