export const PersonalInfo = {
    "whatsappPhone": "19064985801",
    "displayPhone": "+1-906-498-5801",
    "email": "contact@getawriter.org"
}

const subject1 = 'Introduction to Health Policy'

export const QuestionList = [
    {
        "subject": subject1,
        "question": "In a free market, multiple patients and providers act interdependently",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: True"
        }
    },
    {
        "subject": subject1,
        "question": "When providers deliver unnecessary services with the objective of protecting themselves against lawsuits, this practice is called:",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: Defensive medicine"
        }
    },
    {
        "subject": subject1,
        "question": "Under free market conditions, the relationship between the quantity of medical services demanded and the price of medical services is: ",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: Inverse"
        }
    },
    {
        "subject": subject1,
        "question": "In a free market, who would pay for the delivery of health care services?",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: Patients"
        }
    },
    {
        "subject": subject1,
        "question": "The central agency that delivers health care in the United States is Medicare.",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: False"
        }
    },
    {
        "subject": subject1,
        "question": "True or False? In a single-payer system, the primary payer usually is an insurance company. ",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: False"
        }
    },
    {
        "subject": subject1,
        "question": "Moral hazard has to do with insured patients' demand for health care services.",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: True"
        }
    },
    {
        "subject": subject1,
        "question": "Switzerland used to have a system that mirrored the U.S., a largely employer-based system",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: True"
        }
    },
    {
        "subject": subject1,
        "question": "Those utilizing the National Health Service in Britain have insurance plans that require fees, and copays, and their medical bills result at times in 'medical' bankruptcies but a much lower rates to the U.S.",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: False"
        }
    },
    {
        "subject": subject1,
        "question": "In Japan, most hospitals are operated by the government.",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: False"
        }
    },
    {
        "subject": subject1,
        "question": "Germany maintains a largely private system of doctors and hospitals, and patients pay into sickness funds that will also pay for alternative health care treatments such as homeopathy and spas.",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: True"
        }
    },
    {
        "subject": subject1,
        "question": " LAMal, the system in Switzerland, was approved by referendum of the citizens in which universal coverage was accomplished by requiring participation, and with government paying for the poor.",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: True"
        }
    },
    {
        "subject": subject1,
        "question": "Which of the following is not true about Taiwan's hc system?",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is:  Control of costs is done as in the US with primary care doctors serving as gatekeepers."
        }
    },
    {
        "subject": subject1,
        "question": "Which countries' health systems were compared to the US in 'Sick Around the World?'",
        "image": "",
        "answer": {
            "image": "",
            "provided": true,
            "text": "The answer is: UK, Germany, Switzerland, Japan, and Taiwan"
        }
    },
]