import React from 'react'
import { FaFacebookMessenger, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { MdCall, MdOutlineMarkEmailRead } from 'react-icons/md'

import paypal from '../assets/paypal.png'
import mastercard from '../assets/mastercard.png'
import visa from '../assets/visa.png'
import payoneer from '../assets/payoneer.png'
import { PersonalInfo } from '../utls/Data'

const Footer = () => {
   const msg = "Hello, I'm looking for help with my task. Are you available for consultation? "

   return (
      <section className='w-full bottom-0 bg-grayMain pt-6 overflow-x-scroll p-4' id='footer'>
         <div className='max-w-[85rem] mx-auto flex flex-col sm:flex-row gap-5 justify-between items-center flex-[3]'>
            <div className='w-full flex flex-col gap-2 items-center md:items-start flex-[1]'>
               <a href={`https://wa.me/${PersonalInfo.whatsappPhone}?text=${msg}`} rel="noopener noreferrer" target='_blank'
                  className='font-bold text-black text-lg hover:text-green-500 flex items-center gap-2 hover:underline'>
                  <MdCall className=' text-mainColor' /> {PersonalInfo.displayPhone}
               </a>
               <a href={`mailto:${PersonalInfo.email}?subject=ASSIGNMENT HELP&body=${msg}`} className='hover:text-green-500 flex items-center gap-2 hover:underline font-bold text-black'>
                  <MdOutlineMarkEmailRead className=' text-mainColor w-6 h-6' /> {PersonalInfo.email}
               </a>

               <a href={`mailto:${PersonalInfo.email}?subject=ASSIGNMENT HELP&body=${msg}`}
                  rel="noopener noreferrer" target='_blank'
                  className='font-bold text-lg flex items-center gap-2 bg-green-500 p-4 text-white rounded-sm hover:bg-green-400 justify-center'>
                  <p className='flex items-center gap-2 animate-wiggle'> Drop us a Line </p>
               </a>
            </div>
            <div className='flex flex-col items-center gap-2 flex-[1]'>
               <div className='flex items-center gap-2'>
                  <p>Follow us:</p>
                  <span className='p-1 bg-black text-white rounded-full hover:animate-spin-slow'>
                     <FaTwitter />
                  </span>
                  <span className='p-1 bg-black text-white rounded-full hover:animate-spin-slow'>
                     <FaInstagram />
                  </span>
                  <span className='p-1 bg-black text-white rounded-full hover:animate-spin-slow'>
                     <FaFacebookMessenger />
                  </span>
                  <span className='p-1 bg-black text-white rounded-full hover:animate-spin-slow'>
                     <FaLinkedinIn />
                  </span>
               </div>
            </div>
            <div className='flex items-center gap-2 flex-[1] pb-2'>
               <p>We accept:</p>
               <img src={paypal} alt="" className='h-8 object-contain' />
               <img src={mastercard} alt="" className='h-8 object-contain' />
               <img src={visa} alt="" className='h-8 object-contain' />
               <img src={payoneer} alt="" className='h-8 object-contain' />
            </div>
         </div>
         <p className='flex justify-center m-auto pb-3'><a href="https://brijtechke.info" rel="noreferrer" target='_blank'>Developed By BrijTech &copy;{new Date().getFullYear()} GetaWriter</a></p>
      </section>
   )
}

export default Footer