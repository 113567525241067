import React, { useState, useCallback, useEffect } from 'react'
import Navbar from '../components/Navbar1'
import Questions from '../components/paperbank/Questions'
import SearchBar from '../components/paperbank/SearchBar'
import Footer from '../components/Footer'
import { getQuestionsRequest } from '../utls/Question'
import { Helmet } from "react-helmet";

function PaperBank() {
    const [topColor, setTopColor] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const [y, setY] = useState(window.scrollY);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            if (window.pageYOffset <= 20) {
                setTopColor(false)
            } else if (y < window.scrollY) {
                setTopColor(true)
            }
            setY(window.scrollY);
        }, [y]
    );

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);

        return () => {
            window.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation]);

    useEffect(() => {
        setLoading(true)
        return getQuestionsRequest(setData, setLoading)
    }, [])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Question and Answers</title>
                <meta name="keywords" content="Writing, articles, academic writing, Homework help, writers, assignment help, assistance, questions" />
                <meta name="topic" content="Questions and Answers Help" />
                <meta name="url" content="https://getawriter.org/paperbank" />
                <meta name="identifier-URL" content="https://getawriter.org/paperbank" />
                <link rel="canonical" href="https://getawriter.org/paperbank" />
            </Helmet>
            <Navbar topColor={topColor} />
            <SearchBar setData={setData} setLoading={setLoading} loading={loading} searchValue={searchValue} setSearchValue={setSearchValue} />
            <Questions data={data} setData={setData} setLoading={setLoading} setSearchValue={setSearchValue} />
            <Footer />
        </div>
    )
}

export default PaperBank