import React, { useState } from 'react'
import { MdCall, MdOutlineMarkEmailRead } from 'react-icons/md'
import { BsWhatsapp } from 'react-icons/bs'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import expert1 from '../assets/experts/expert-1.webp'
import expert2 from '../assets/experts/expert-2.webp'
import expert3 from '../assets/experts/expert-3.webp'
import { PersonalInfo } from '../utls/Data'

const FAQ = () => {
    const msg = "Hello, I'm looking for help with my task. Are you available for consultation? "
    const [legit, setLegit] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [reliable, setReliable] = useState(false)
    const [honest, setHonest] = useState(false)
    const [why, setWhy] = useState(false)

    return (
        <section className='py-10 px-3' id='faq'>
            <div className='max-w-[85rem] m-auto flex md:flex-row flex-col gap-4 flex-[5]'>
                <div className='flex flex-col flex-[2] gap-8 relative items-center md:items-start'>
                    <p className='text-3xl font-bold w-full text-center md:text-left'>Ask Our Team</p>
                    <p className='w-[80%] text-center md:text-left'>Want to contact us directly? No problem. We are always here for you.</p>
                    <div className='flex'>
                        <img src={expert1} alt="expert1" className='shadow-lg object-contain rounded-full' />
                        <img src={expert2} alt="expert2" className='shadow-lg object-contain -ml-10 rounded-full' />
                        <img src={expert3} alt="expert3" className='shadow-lg object-contain -ml-10 rounded-full' />
                    </div>
                    <div className='flex flex-col gap-4 h-full'>
                        <a href={`https://wa.me/${PersonalInfo.whatsappPhone}?text=${msg}`}
                            rel="noopener noreferrer" target='_blank'
                            className='font-bold text-black text-lg hover:text-green-500 flex items-center gap-2 hover:underline'>
                            <MdCall className=' text-mainColor w-6 h-6' /> {PersonalInfo.displayPhone}
                        </a>
                        <a href={`mailto:${PersonalInfo.email}?subject=ASSIGNMENT HELP&body=${msg}`} className='hover:text-green-500 flex items-center gap-2 hover:underline font-bold text-black'>
                            <MdOutlineMarkEmailRead className=' text-mainColor w-6 h-6' /> {PersonalInfo.email}
                        </a>
                        <a href={`https://wa.me/${PersonalInfo.whatsappPhone}?text=${msg}`}
                            rel="noopener noreferrer" target='_blank'
                            className='font-bold text-lg flex items-center gap-2 bg-green-500 p-4 text-white rounded-sm hover:bg-green-400 animate-wiggle'>
                            <p className='flex items-center gap-2'> <BsWhatsapp /> Start LiveChat </p>
                        </a>
                    </div>
                </div>
                <div className='flex flex-col flex-[5] gap-8'>
                    <p className='text-3xl font-bold w-full text-center py-2'>Get To Know Us More</p>
                    <div className='bg-grayMain p-8 rounded-md flex flex-col gap-4'>
                        <p className='flex w-full items-center justify-between cursor-pointer' onClick={() => setLegit(!legit)}>
                            <span className='font-bold text-lg w-[90%]'>Is GetaWriter legit?</span>
                            {!legit ? (
                                <MdKeyboardArrowDown
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${legit ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            ) : (
                                <MdKeyboardArrowUp
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${legit ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            )}
                        </p>
                        {legit && (
                            <div className='flex flex-col gap-4'>
                                <p>
                                    GetaWriter is absolutely legit. We know it’s hard to trust the company you’ve just found online so we hope our backstory can help you get to know us better.
                                    GetaWriter was found in 2011 as a part of StudyWorld Media holding, which is an international company group focused on providing education services and developing innovative decisions for education worldwide.
                                </p>
                                <p>
                                    There are more than 3 million students and 50,000 verified experts from over 100 countries registered on our platform already,
                                    and we’re still growing every day. We believe that every communication between student and expert, as well as between user and platform,
                                    should be based on respect thus we provide our clients with legit services only and take the verification of experts very seriously.
                                </p>
                            </div>

                        )}
                    </div>
                    <div className='bg-grayMain p-8 rounded-md flex flex-col gap-4'>
                        <p className='flex w-full items-center justify-between cursor-pointer' onClick={() => setPrivacy(!privacy)}>
                            <span className='font-bold text-lg w-[90%]'>How does GetaWriter use and store my personal data?</span>
                            {!privacy ? (
                                <MdKeyboardArrowDown
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${privacy ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            ) : (
                                <MdKeyboardArrowUp
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${privacy ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            )}
                        </p>
                        {privacy && (
                            <div className='flex flex-col gap-4'>
                                <p>
                                    Using GetaWriter is absolutely safe. We care about security, therefore we encrypt all personal data to make every user
                                    feel safe while using GetaWriter and we don’t share any personal information with any third parties without your permission.
                                </p>
                            </div>

                        )}
                    </div>
                    <div className='bg-grayMain p-8 rounded-md flex flex-col gap-4'>
                        <p className='flex w-full items-center justify-between cursor-pointer' onClick={() => setReliable(!reliable)}>
                            <span className='font-bold text-lg w-[90%]'>Is GetaWriter reliable?</span>
                            {!reliable ? (
                                <MdKeyboardArrowDown
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${reliable ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            ) : (
                                <MdKeyboardArrowUp
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${reliable ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            )}
                        </p>
                        {reliable && (
                            <div className='flex flex-col gap-4'>
                                <p>
                                    GetaWriter is absolutely reliable, in fact, it's commonly used by the students of the world's top universities.
                                    Our processes are very transparent so you can see the final price right after you fill your project request form.
                                    We don’t charge any hidden fees or anything like that, and you know the final price right from the start.
                                    But if you’re still hesitating, let us tell you that we provide a 20-day warranty on each project so
                                    that every client could make any edits, if needed, for free. During this warranty period,
                                    you can also request a refund if you think that our expert wasn’t able to meet your requirements.
                                    Only after the client accepts their project as completed, we transfer payment to the expert.
                                </p>
                            </div>

                        )}
                    </div>
                    <div className='bg-grayMain p-8 rounded-md flex flex-col gap-4'>
                        <p className='flex w-full items-center justify-between cursor-pointer' onClick={() => setHonest(!honest)}>
                            <span className='font-bold text-lg w-[90%]'>Aren't your services considered academic dishonesty?</span>
                            {!honest ? (
                                <MdKeyboardArrowDown
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${honest ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            ) : (
                                <MdKeyboardArrowUp
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${honest ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            )}
                        </p>
                        {honest && (
                            <div className='flex flex-col gap-4'>
                                <p>
                                    No, we don’t support academic dishonesty and go against appropriating someone else’s intellectual property and claiming it’s your own.
                                    We provide our clients with professional academic assistance and expert guidance to help them handle difficult studies.
                                    We stand for high ethical standards in education.
                                </p>
                            </div>

                        )}
                    </div>
                    <div className='bg-grayMain p-8 rounded-md flex flex-col gap-4'>
                        <p className='flex w-full items-center justify-between cursor-pointer' onClick={() => setWhy(!why)}>
                            <span className='font-bold text-lg w-[90%]'>Why should I choose GetaWriter when I can use other online writing companies?</span>
                            {!why ? (
                                <MdKeyboardArrowDown
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${why ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            ) : (
                                <MdKeyboardArrowUp
                                    className={`w-8 h-8 p-1 rounded-full border-[1px] border-green-500 shadow-inner shadow-green-200 ${why ? 'bg-primaryColor text-white' : 'text-primary'}`}
                                />
                            )}
                        </p>
                        {why && (
                            <div className='flex flex-col gap-4'>
                                <p>
                                    You can rely on us because we really help people seeking academic help.
                                    You and your studies matter to us and our experts, that’s why we provide you with guarantees on each and every project.
                                    We won’t charge you until you’re satisfied with the outcome of cooperation with our expert.
                                    You can always be sure you get professional help and remain anonymous while using GetaWriter.
                                </p>
                            </div>

                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ