import sanityClient from "../Client";

export const getQuestionsRequest = (setData, setLoading) => {
    sanityClient
        .fetch(
            `*[_type == "question"]{
                subject,
                question,
                questionImage[]{
                    asset->{
                    _id,
                    url
                    },
                },
                isAnswerProvided,
                answer,
                answerImage[]{
                    asset->{
                    _id,
                    url
                    },
                },
                createdAt
                }`
        )
        .then((data) => {
            console.log('sanity call ====> ');
            setData(data);
            setLoading(false)
        })
        .catch(error => {
            console.log(error);
            setLoading(false)
        });
}

export const searchQuestionsRequest = (setData, setLoading, query) => {
    sanityClient
        .fetch(
            `*[_type == "question" && [question, answer, subject] match "${query}*"]{
                subject,
                question,
                questionImage[]{
                    asset->{
                    _id,
                    url
                    },
                },
                isAnswerProvided,
                answer,
                answerImage[]{
                    asset->{
                    _id,
                    url
                    },
                },
                createdAt
                }`
        )
        .then((data) => {
            console.log('sanity call ====> ', query);
            setData(data);
            setLoading(false)
        })
        .catch(error => {
            console.log(error);
            setLoading(false)
        });
}

