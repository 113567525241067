import React from 'react'
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
// Modal.setAppElement(document.getElementById('questionModal'));

function QuizDetail({ modalIsOpen, setIsOpen, quizData, onRedirectClick }) {

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Question n Answer"
      className='absolute max-w-2xl m-auto left-0 right-0 bottom-0 top-0 opacity-100 bg-gray-100 p-10 rounded-md shadow-lg border'
    >
      <div className='flex flex-col gap-2'>
        <div className='flex gap-2 flex-col'>
          <p className='font-semibold text-xl underline'> {quizData['subject']}</p>
          <p><span className='font-bold text-xl'>Q.</span>{quizData['question']}</p>
          {/* {quizData['image'] &&
            <img src={quizData['image']} alt="question" className='object-contain max-h-52 max-w-lg rounded-md m-auto' />
          } */}
        </div>

        <div className='flex flex-col gap-4'>
          {quizData['isAnswerProvided'] ? (
            <>
              <p className=''>
                <span className='font-bold text-xl'>A. </span>
                <span className='text-green-500 font-semibold'>{quizData['answer']}</span>
              </p>
              <a href='/#hero' className='text-xs cursor-pointer'>(<span className='text-xs text-blue-600'>
                Contact us</span> to get your assignments/quizzes and homework done by expert tutors)</a>
            </>
          ) : (
            <div className='bg-primaryColor rounded-md cursor-pointer animate-bounce p-2 m-auto'>
              <Link to='/#hero' className=' '>
                <p className='text-lg text-white font-bold'>Reach out for the answer</p>
              </Link>
            </div>
          )}
        </div>



        {/* <div className='flex flex-col gap-3'>
          {
            quizData['answer']['provided'] ? (
              <p><span className='font-bold text-xl'>Answer.</span> {quizData['answer']['text']}</p>
            ) : (
              <p> <span className='font-bold text-xl'>Answer.</span>
                <Link to={`/#hero${quizData['question']}`} className='text-blue-500' onClick={onRedirectClick}>
                  {quizData['answer']['text']}
                </Link>
              </p>
            )
          }
          {quizData['answer']['image'] &&
            <img src={quizData['answer']['image']} alt="answer" className='object-contain max-h-52 max-w-lg rounded-md' />
          }
        </div> */}
      </div>
    </Modal>
  );
}

export default QuizDetail