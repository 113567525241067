import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import logo from '../assets/getawriter.png'

export default function NavBar({ topColor }) {
    const [navbar, setNavbar] = useState(false);
    const dropdownRef = useRef(null);
    const [showContext, setShowContext] = useState(false)

    useEffect(() => {
        const pageClickEvent = (e) => {
            if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
                setShowContext(!showContext)
            }
        };

        // If the item is active (ie open) then listen for clicks
        if (showContext) {
            window.addEventListener('click', pageClickEvent);
        }

        return () => {
            window.removeEventListener('click', pageClickEvent);
        }

    }, [showContext]);

    return (
        <nav className={`w-full z-[1000] fixed top-0 ${(topColor || navbar) && 'bg_navbar'} shadow-md`}>
            <div className="justify-between px-4 mx-auto lg:max-w-[85rem] md:items-center md:flex md:px-8">
                <div>
                    <div className="flex items-center justify-between py-3 md:py-5 md:block">
                        <div className="flex items-center gap-2">
                            <Link to='/' className='flex flex-row'>
                                <img src={logo} alt="" className='w-8' />
                                <h1 className={`text-black font-black styled-font md:text-4xl text-3xl ${topColor && 'border-r pr-2 border-gray-400'}`}>GetaWriter</h1>
                            </Link>
                            {topColor &&
                                <small className='w-1/2 text-[10px] pl-2'>A-Plus Homework Help For College Students</small>
                            }
                        </div>
                        <div className="md:hidden">
                            <button
                                className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                onClick={() => setNavbar(!navbar)}
                            >
                                {navbar ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`mt-4 md:block md:pb-0 md:mt-0 flex-col ${navbar ? "block" : "hidden"
                        }`}>
                    <nav ref={dropdownRef} className='md:gap-8 gap-2 text-black font-semibold text-lg flex-col flex md:flex-row'>
                        <a href='/#subjects'>Subjects</a>
                        <Link rel='canonical' to='/paperbank'>Q&A</Link>
                        <a href='/#faq'>FAQ</a>
                        <a href='/#footer'>Contacts</a>
                    </nav>
                </div>
                <div>
                    <div
                        className={`justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : "hidden"
                            }`}
                    >
                        <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">

                            <div className={`flex items-center gap-4`}>
                                <a href='/#reachout' className='px-4 py-2 bg-primaryColor rounded-md text-white font-bold md:text-lg hover:bg-green-400 animate-bounce'>
                                    Reach Out
                                </a>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}