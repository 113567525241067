import React, { useCallback, useEffect, useState } from 'react'
import AssignmentTypes from '../components/AssignmentTypes'
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Navbar from '../components/Navbar'
import Navbar1 from '../components/Navbar1'
import Popular from '../components/Popular'
import Questions from '../components/Questions'
import ReachOut from '../components/ReachOut'
import Specials from '../components/Specials'
import Standout from '../components/Standout'
import Steps from '../components/Steps'

const Home = () => {
  const [topColor, setTopColor] = useState(false)

  const [y, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (window.pageYOffset <= 20) {
        setTopColor(false)
      } else if (y < window.scrollY) {
        setTopColor(true)
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);


  return (
    <div>
      <div className='min-h-screen'>
        {/* <Navbar topColor={topColor} /> */}
        <Navbar1 topColor={topColor} />
        <Hero />
        <Steps />
        <Specials />
        <Standout />
        <FAQ />
        <Popular />
        <ReachOut />
        <AssignmentTypes />
        <Questions />
      </div>
      <Footer />
    </div>
  )
}

export default Home