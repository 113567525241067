import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import QuizDetail from './QuizDetail';
import ReactPaginate from 'react-paginate';
import { getQuestionsRequest } from '../../utls/Question';

function Questions({ data, setData, setLoading, setSearchValue }) {
    const [modalIsOpen, setIsOpen] = useState(false)
    const [questionInf, setQuestionInf] = useState({})
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentItems, setCurrentItems] = useState(null);


    const onRedirectClick = () => {
        window.removeEventListener("scroll");
    }

    const openModal = (info) => {
        setIsOpen(true);
        setQuestionInf(info)
    }

    const itemsPerPage = 10

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [data, itemOffset, itemsPerPage])


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    const handleResetSearch = () => {
        getQuestionsRequest(setData, setLoading)
        setSearchValue('')
    }

    return (
        <section className='py-10 px-3'>
            <div className='max-w-[85rem] m-auto'>
                <div className='pb-4'>
                    <p className='md:text-2xl font-bold text-xl'>Questions</p>
                    <small>(click for answers)</small>
                </div>
                {
                    currentItems?.length > 0 ? (
                        <div className='grid grid-cols-1 md:grid-cols-2 flex-col gap-10 justify-center w-full'>
                            {
                                currentItems?.map((data, i) => (
                                    <div key={i} className='flex flex-col gap-1 border-b'>
                                        <p className='font-semibold text-xl'>{data['subject']}</p>
                                        <Link to='' onClick={() => openModal(data)}> <span className='font-semibold text-xl' id='questionModal'>
                                            Q. </span> <span className='text-blue-600'>{data['question']}</span>
                                        </Link>
                                        {/* {data['image'] &&
                                    <img src={data['image']} alt="question" className='object-contain max-h-52 max-w-lg aspect-1.5 rounded-md pl-4' />
                                } */}
                                        {/* {
                                    data['answer']['provided'] ? (
                                        <p><span className='font-bold text-xl'>Answer.</span> {data['answer']['text']}</p>
                                    ) : (
                                        <p> <span className='font-bold text-xl'>Answer.</span>
                                            <Link to='/#hero' className='text-blue-500' onClick={onRedirectClick}>
                                                {data['answer']['text']}
                                            </Link>
                                        </p>
                                    )
                                }
                                {data['answer']['image'] &&
                                    <img src={data['answer']['image']} alt="answer" className='object-contain max-h-52 max-w-lg rounded-md pl-4' />
                                } */}
                                    </div>
                                ))
                            }
                        </div>
                    ) : (
                        <div className='flex gap-3 items-center'>
                            {/* <Skeleton height={'5rem'} />
                            <Skeleton height={'5rem'} /> */}
                            <p>No match found! </p>
                            <button onClick={handleResetSearch} className='px-2 py-1 bg-mainColor rounded-md'>Undo</button>
                        </div>
                    )
                }
                <div>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        containerClassName='pagination'
                        previousLabel="prev"
                        renderOnZeroPageCount={null}
                        className='pagination'
                        activeClassName='active'
                        marginPagesDisplayed={2}
                    />
                </div>

                {
                    modalIsOpen &&
                    <QuizDetail modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} quizData={questionInf} onRedirectClick={onRedirectClick} />
                }
            </div>
        </section>
    )
}

export default Questions