import React, { useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { ThreeDots } from 'react-loader-spinner'


import soln from '../../assets/soln.png'
import { searchQuestionsRequest } from '../../utls/Question';

function SearchBar({ setData, setLoading, loading, searchValue, setSearchValue }) {

    const onSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        const query = e.target.searchTag.value
        searchQuestionsRequest(setData, setLoading, query)
        setSearchValue(query)
    }

    return (

        <div className='bg-gradient-to-b from-green-400 to-slate-50 px-3'>
            <div className='max-w-[85rem] m-auto flex flex-[2] p-2'>
                <div className='flex-[1] mt-20 flex flex-col gap-4'>
                    <p className='text-[2rem] lg:text-[3.5rem] font-[600]'>Step by Step Solutions</p>
                    <p className='lg:text-[1.3rem] w-5/6'>Contact us to get your assignments/quizzes and homework done by expert tutors</p>
                    <form className='flex flex-row items-center justify-center rounded-md w-full bg-white p-1' onSubmit={onSubmit}>
                        <input type="text" name="searchTag"
                            className='w-full h-10 p-1 border-none outline-none'
                            size='80' placeholder='Find an answer to your question'
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <button type="submit">
                            {!loading ? (
                                <BsSearch className='w-10 h-full bg-primaryColor p-1 text-white rounded-md' />
                            ) : (
                                <ThreeDots
                                    height="40"
                                    width="40"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            )}

                        </button>
                    </form>
                </div>
                <div className='hidden flex-[1] relative sm:flex'>
                    <img src={soln} className='h-full w-full object-contain absolute' alt="" />
                </div>
            </div>
        </div>
    )
}

export default SearchBar