import React, { useState } from 'react'
import writer from '../assets/writer.svg'
import { PersonalInfo } from '../utls/Data'

const Hero = () => {
    const [des, setDes] = useState('')

    return (
        <div className='bg-gradient-to-b from-green-400 to-white px-3' id='hero'>
            <div className='max-w-[85rem] m-auto flex flex-[2] p-2'>
                <div className='flex-[1] mt-20 flex flex-col gap-4'>
                    <h1 className='text-[2rem] lg:text-[3.5rem] font-[700]'>A-Plus Homework Help For College Students</h1>
                    <p className='lg:text-[1.3rem] w-5/6'>Get personalized expert assistance in any academic field. 100+ courses and programs covered.</p>
                    <textarea
                        name="brief" id="" rows="4"
                        onChange={(e) => setDes(e.target.value)}
                        value={des}
                        className='border-2 sm:w-4/5 border-black rounded-[3px] text-black p-3 lg:text-xl font-medium outline-emerald-500'
                        placeholder='Describe your project briefly'>
                    </textarea>
                    <div className='lg:text-right sm:w-4/5  flex justify-end items-center gap-4'>
                        <p className='lg:text-[1.3rem]'>Find an Expert</p>
                        <button className='bg-primaryColor p-4 hover:bg-green-400 rounded-[5px] font-semibold text-white animate-wiggle'>
                            <a href={`mailto:${PersonalInfo.email}?subject=ASSIGNMENT HELP&body=${des}`}
                                className='font-bold text-lg'>Email</a>
                        </button>
                        <button className='bg-primaryColor p-4 hover:bg-green-400 rounded-[5px] font-semibold animate-wiggle'>
                            <a href={`https://wa.me/${PersonalInfo.whatsappPhone}?text=${des}`} rel="noopener noreferrer" target='_blank'
                                className='font-bold text-white text-lg'>
                                Whatsapp
                            </a>
                        </button>
                    </div>
                </div>
                <div className='hidden flex-[1] relative sm:flex'>
                    <img src={writer} className='h-full w-full object-contain absolute' alt="" />
                </div>
            </div>
        </div>
    )
}

export default Hero