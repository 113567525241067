import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const Questions = () => {
    const sliderParams = {
        arrows: true,
        autoPlay: false,
        shouldResetAutoplay:true,
        className:'pb-5',
        centerMode: false,
        slidesToSlide: 1,
        containerClass: 'carousel-container',
        responsive: responsive
    };
    return (
        <section className='py-10 px-3'>
            <div className='max-w-[85rem] m-auto flex justify-evenly flex-col gap-10 items-center'>
                <p className='text-3xl md:text-5xl md:leading-[3rem] font-bold md:w-full w-4/5 text-center'>Any Questions Left?</p>
                <div className='w-full'>
                    <Carousel {...sliderParams} shouldResetAutoplay >
                        <div className='sm:mr-6 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 p-10 w-full rounded-b-xl'>
                                <p className='font-bold text-2xl'>Is getting assignment help considered cheating?</p>
                                <p>
                                    Using GetaWriter services is not cheating. Cheating involves plagiarism or stealing other people’s
                                    work and using it as your own. We stand against copying others’ content and
                                    claiming it as original content created by you. You can learn more about our ethical principles by checking our Honor Code.
                                </p>
                            </div>
                        </div>
                        <div className='sm:mr-6 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 p-10 w-full rounded-b-xl'>
                                <p className='font-bold text-2xl'>Is assignment help worth the money?</p>
                                <p>
                                    Definitely yes. Sometimes it’s really unbearable for a student to understand
                                    the ins and outs of the topic or even a whole discipline all by themselves.
                                    In this case, GetaWriter can come in really handy. Here you can find experts in various academic
                                    fields to share their knowledge and help you get your assignments done.
                                </p>
                            </div>
                        </div>
                        <div className='sm:mr-6 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 p-10 w-full rounded-b-xl'>
                                <p className='font-bold text-2xl'>How long will it take for my assignment to be ready?</p>
                                <p>
                                    It really depends on the assignment type and complexity. If you’re not sure how much time
                                    work on your task may require, you can reach out to our support team to make sure our experts
                                    will be able to fulfill your requirements by the deadline.
                                </p>
                            </div>
                        </div>
                        <div className='sm:mr-6 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 p-10 w-full rounded-b-xl'>
                                <p className='font-bold text-2xl'>Can I edit the assignment if I don’t like it?</p>
                                <p>
                                    Of course, you can. We offer free revisions on each project and even a money-back guarantee,
                                    which means you can request a refund if you’re not satisfied with our assignment help service.
                                    Also, we don’t transfer your funds to the expert until you accept your project as completed,
                                    so you can be sure your money is safe and sound.
                                </p>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
    );
}

export default Questions