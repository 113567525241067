import React from 'react'

import step1 from '../assets/step1.mp4'
import step2 from '../assets/step2.mp4'

const Steps = () => {
  return (
        <section className='py-10 px-3'>
            <div className='max-w-5xl m-auto'>
              <p className='md:text-5xl font-bold text-center text-3xl'>How It Works</p>
              <div className='py-8 flex flex-col gap-6 justify-center'>
                  <div className='flex flex-col items-center md:flex-row flex-[2] gap-10'>
                      <div className='flex flex-col gap-2 flex-[1] items-center md:items-start'>
                          <span className='font-bold md:text-2xl text-xl'>1. Fill in a brief</span>
                          <p className='md:text-xl w-3/4'>Tell us what you need help with, describe your project requirements, and set the deadline.</p>
                      </div>
                      <div className='bg-gray-100 rounded-[50px] rounded-b-sm flex p-8 flex-[1]'>
                          <video className='rounded-xl' autoPlay loop muted>
                              <source src={step1} type='video/mp4' />
                          </video>
                      </div>
                  </div>
                  <div className='flex flex-col justify-between items-center md:flex-row flex-[2] gap-10'>
                      <div className='bg-gray-100 rounded-[50px] rounded-t-sm flex p-8 flex-[1]'>
                          <video className='rounded-xl' autoPlay loop muted>
                              <source src={step2} type='video/mp4' />
                          </video>
                      </div>
                      <div className='flex flex-col gap-2 flex-[1] items-center md:items-center'>
                          <span className='font-bold md:text-2xl text-xl'>2. Get it done on time</span>
                          <p className='md:text-xl w-3/4 text-center md:items-end md:text-center'>Chat with the expert directly, discuss your project in detail, and get professional academic assistance by the deadline.</p>
                          <div className='flex justify-start mt-5'>
                              <button className='px-8 py-2 bg-green-500 rounded-[0.25rem] text-white font-bold animate-wiggle'>
                                  <a href='#reachout'
                                      className='animate-ping'>
                                    Let's go
                                  </a>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
        </section>
  )
}

export default Steps