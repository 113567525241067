import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { AiFillWechat } from 'react-icons/ai'
import { MdOutlinePriceChange, MdSecurity, MdSupportAgent } from 'react-icons/md'
import { RiSecurePaymentLine, RiArrowGoBackLine } from 'react-icons/ri'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const Standout = () => {
    const sliderParams = {
        arrows: true,
        autoPlay: false,
        shouldResetAutoplay:true,
        className:'pb-5',
        centerMode: false,
        slidesToSlide: 1,
        containerClass: 'carousel-container',
        responsive: responsive,
        showDots: true
    };
    return (
        <section className='py-10 px-3'>
            <div className='max-w-[85rem] m-auto flex justify-evenly flex-col gap-10 items-center'>
                <p className='md:text-5xl font-bold md:w-2/3 w-[90%] text-center text-3xl md:leading-[3.5rem] '>How GetaWriter Stands Out From Assignment Help Services?</p>
                <div className='w-full'>
                    <Carousel {...sliderParams} shouldResetAutoplay >
                        <div className='sm:mr-10 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 px-10 py-5 w-full rounded-b-xl'>
                                <AiFillWechat className='w-12 h-12 text-green-500' />
                                <p className='font-bold text-lg'>Huge Community of Experts</p>
                                <p>
                                    We bring together verified college professors, practicing scientists, independent researchers,
                                    freelance journalists, essay writers, and thousands of other experts in different fields of study,
                                    not only from the USA, but from all over the world. They are ready to share their knowledge and
                                    provide you with professional assignment help.
                                </p>

                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 px-10 py-5 w-full rounded-b-xl'>
                                <MdOutlinePriceChange className='w-12 h-12 text-green-500' />
                                <p className='font-bold text-lg'>Affordable Prices</p>
                                <p>
                                    As a strong team of developers, we use high-tech solutions such as AI-based tools to cover various parts of the process of each project.
                                    For example, we automate and optimize plagiarism checking and grammar checking,
                                    which lets us provide students with cheap homework assignment help of outstanding quality.
                                </p>

                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 px-10 py-5 w-full rounded-b-xl'>
                                <RiArrowGoBackLine className='w-12 h-12 text-green-500'/>
                                <p className='font-bold text-lg'>Money-Back Guarantee</p>
                                <p>
                                    We provide you with a warranty on each project. Your payment goes to an expert only after you have accepted the project as completed.
                                    If you are not satisfied with the result of your cooperation with our expert,
                                    you can request any edits for free or even a refund during a warranty period.
                                </p>
                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 px-10 py-5 w-full rounded-b-xl'>
                                <MdSecurity className='w-12 h-12 text-green-500' />
                                <p className='font-bold text-lg'>Data Security</p>
                                <p>
                                    We care about your security, therefore we encrypt all personal data to make every user feel safe while using GetaWriter.
                                    We don’t share any personal information with any third parties without your permission.
                                </p>
                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 px-10 py-5 w-full rounded-b-xl'>
                                <RiSecurePaymentLine className='w-12 h-12 text-green-500' />
                                <p className='font-bold text-lg'>Safe Payment Methods</p>
                                <p>
                                    We use fast, convenient, modern, and safe payment tools and solutions, such as Paypal, Payoneer, and Visa/Mastercard, for payments and withdrawals.
                                    And we're constantly working on adding more options.
                                    Also, you can pay the entire amount at once or in two installments if it is more convenient for you.
                                </p>
                            </div>
                        </div>
                        <div className='sm:mr-10 mr-4 bg-grayMain rounded-2xl h-full'>
                            <div className='flex flex-col gap-4 px-10 py-5 w-full rounded-b-xl'>
                                <MdSupportAgent className='w-12 h-12 text-green-500' />
                                <p className='font-bold text-lg'>24/7 Support</p>
                                <p>
                                    Our support team is here for you 24/7 to answer any questions and resolve any issues you might have.
                                    Feel free to contact us via phone, email, or chat on the website whenever you need it.
                                </p>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </div>
        </section>
    );
}

export default Standout